var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "home" }, [
    _c(
      "div",
      {
        ref: "banner",
        staticClass: "banner main",
        on: { mouseenter: _vm.enterView, mouseleave: _vm.leaveView },
      },
      [
        _c("div", { staticClass: "background-img" }, [
          _c("img", { ref: "frame", attrs: { src: _vm.frameUrl } }),
          _c(
            "div",
            {
              ref: "play",
              staticClass: "view-player csp",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.viewPlay.apply(null, arguments)
                },
              },
            },
            [_c("div", { staticClass: "player-triangle" })]
          ),
          _c("div", {
            ref: "pause",
            staticClass: "view-pause",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.viewPause.apply(null, arguments)
              },
            },
          }),
        ]),
        _c("div", { staticClass: "banner-info" }, [
          _c("h3", { staticClass: "banner-title" }, [_vm._v("观天者")]),
          _c("h4", { staticClass: "banner-subtitle" }, [
            _vm._v("以你的视角看天气"),
          ]),
          _c("div", { staticClass: "banner-desc" }, [
            _c("p", { staticClass: "banner-weather" }, [
              _vm._v(
                _vm._s(
                  _vm.currentView && _vm.currentView.weather[_vm.currentFrame]
                )
              ),
            ]),
            _c("p", { staticClass: "banner-location flex-align" }, [
              _c("img", {
                staticClass: "loaction-avg",
                attrs: {
                  src: require("@/assets/images/home/Subtract.svg"),
                  alt: "subtract",
                },
              }),
              _vm._v(
                " " + _vm._s(_vm.currentView && _vm.currentView.location) + " "
              ),
            ]),
            _c("p", { staticClass: "banner-date" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.currentView &&
                      _vm
                        .dayjs(
                          _vm.currentView.timestamps[_vm.currentFrame] * 1000
                        )
                        .utc()
                        .local()
                        .format("YYYY-MM-DD THH:mm:ssZ")
                  ) +
                  " "
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "banner-list-wrap" }, [
          _c(
            "ul",
            { staticClass: "banner-list flex-layout" },
            _vm._l(_vm.viewPortsData, function (item, index) {
              return _c(
                "li",
                {
                  key: index,
                  staticClass: "banner-list-item csp",
                  class: { currentView: _vm.currentViewIndex === index },
                  style: {
                    backgroundImage:
                      "url(" + _vm.viewPortsData[index].urls[0] + ")",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.switchView(index)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(`0${index + 1}`))]
              )
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "controller flex-align" },
            [
              _c("div", {
                staticClass: "el-icon-arrow-left",
                on: {
                  click: function ($event) {
                    return _vm.switchFrames("left")
                  },
                },
              }),
              _c("div", {
                staticClass: "el-icon-arrow-right",
                on: {
                  click: function ($event) {
                    return _vm.switchFrames("right")
                  },
                },
              }),
              _c("progressBar", {
                attrs: { progress: _vm.progress },
                on: { updateFrame: _vm.updateCurrentFrame },
              }),
            ],
            1
          ),
        ]),
      ]
    ),
    _c(
      "div",
      { staticClass: "deploy main" },
      [
        _c("h3", { staticClass: "deploy-title" }, [
          _vm._v(
            "观天派累计部署共 " +
              _vm._s(_vm.deployData["devices-volume"]) +
              " 台"
          ),
        ]),
        _c("div", { staticClass: "deploy-data" }, [
          _vm._m(0),
          _c("div", { staticClass: "data" }, [
            _c("p", { staticClass: "data-num" }, [
              _vm._v(_vm._s(_vm.deployData["city-coverage"])),
            ]),
            _c("p", { staticClass: "data-title" }, [_vm._v("部署覆盖城市")]),
          ]),
        ]),
        _c("globe", { attrs: { pointList: _vm.distributionList } }),
      ],
      1
    ),
    _c("div", { staticClass: "about-us main" }, [
      _c("div", { staticClass: "left-about-info" }, [
        _c("h3", { staticClass: "about-title" }, [_vm._v("关于我们")]),
        _c("p", { staticClass: "about-discribe" }, [
          _vm._v(" " + _vm._s(_vm.aboutDiscritbe) + " "),
        ]),
        _vm._m(1),
      ]),
      _vm._m(2),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "data" }, [
      _c("p", { staticClass: "data-num" }, [_vm._v("1")]),
      _c("p", { staticClass: "data-title" }, [_vm._v("部署覆盖国家")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "about-discribe contact" }, [
      _vm._v(
        "团队目前处于创业初期，正在探索和打磨首款产品。若您想要咨询或者寻求商业合作，欢迎联系邮箱："
      ),
      _c("a", { staticClass: "email csp", attrs: { href: "javascript:;" } }, [
        _vm._v("contact@skyviewor.com"),
      ]),
      _vm._v("。"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "right-about-detail" }, [
      _c("button", { staticClass: "about-button csp" }, [_vm._v("查看详情")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }