<template>
    <div class="progress-bar csp" @mousedown="startDrag" @click="clickProgressBar">
        <div class="progress-fill" ref="fill" :style="{'width':`${progress}%`}">
            <div class="progress-handle csp" ref="handle" @mousedown="startDrag"></div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            // 进度条长度  max:100 min:0
            progress: {
                type: Number,
                default: 100
            }
        },
        data() {
            return {
                barWidth: 0, // .progress-bar宽度
                isDragging: false, // 是否正在拖动进度条
                startX: 0, // 鼠标按下时的x坐标
                startProgress: 0, // 进度条开始时的进度
            }
        },
        methods: {
            // 进度条方法
            startDrag(event) {
                this.isDragging = true;
                this.startX = event.clientX;
                this.startProgress = this.progress;
                this.barWidth = this.$refs.fill.parentElement.offsetWidth;
                document.addEventListener('mousemove', this.drag);
                document.addEventListener('mouseup', this.stopDrag);
            },
            // 进度条滑动方法
            drag(event) {
                if (this.isDragging) {
                    const deltaX = event.clientX - this.startX;
                    const newProgress = (deltaX / this.barWidth) * 100 + this.startProgress;
                    this.setProgress(newProgress);
                }
            },
            // 设置进度条长度
            setProgress(newProgress) {
                this.progress = Math.max(0, Math.min(100, newProgress));
                this.$emit('updateFrame', this.progress);
            },
            // 停止滑动
            stopDrag() {
                this.isDragging = false;
                document.removeEventListener('mousemove', this.drag);
                document.removeEventListener('mouseup', this.stopDrag);
            },
            // 点击进度条
            clickProgressBar(event) {
                if (!this.isDragging) {
                    const rect = this.$refs.fill.parentElement.getBoundingClientRect();
                    const clickX = event.clientX - rect.left;
                    const newProgress = (clickX / this.barWidth) * 100;
                    this.setProgress(newProgress);
                }
            },
        }
    }
</script>

<style lang="less" scoped>
    .progress-bar {
        position: relative;
        margin: 0 8px;
        border-radius: 37px;
        height: 3px;
        width: 220px;
        background-color: #757575;
        user-select: none;

        .progress-fill {
            position: absolute;
            left: 0;
            top: 0;
            border-radius: 37px;
            height: 100%;
            background-color: #fff;

            .progress-handle {
                position: absolute;
                top: 50%;
                left: 100%;
                border: 2px solid #3E6EFF;
                border-radius: 50%;
                width: 10px;
                height: 10px;
                background-color: #fff;
                transform: translate(-50%, -50%);
            }
        }

        &:hover {
            height: 6px;

            .progress-fill .progress-handle {
                width: 12px;
                height: 12px;
            }
        }
    }
</style>